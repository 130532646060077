import React from "react"
import {  graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import get from 'lodash/get'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"


const WorkPageItem = ({datum}) => {

  return <div className="col-lg-6 mb-4 ">
      <div className="d-flex flex-column card h-100">
        <p className="display-small font-200 p-3 bg-light">
          {datum.title}
        </p>
        
        <div className="flex-1 row no-gutters">
          <div className={`${datum.images && datum.images.length > 0 ? 'col-lg-12' : 'col-lg-12'} d-flex flex-column justify-content-between px-3`}>
            <p className="font-200 work-card-text" dangerouslySetInnerHTML={{ __html: datum.description }}></p>
          </div>

          {datum.images && datum.images.length > 0 && <div className="col-lg-12 text-center">
            <img className="img-cover" src={datum.images[0].publicURL}></img>
          </div>}
        </div>

        <div className="bg-light">
          <div  className="p-3">
            <b>Our role</b>
            <p className="font-200" dangerouslySetInnerHTML={{ __html: datum.role }}></p>  
            { datum.home && <div>
              Project website: <a href={datum.home}>{datum.home}</a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  }



const WorkPage = ({data, intl}) => {
  
  const { work } = data
  const workData = get(work, 'edges', []).map(x => x.node).filter(node => node.lang === intl.locale)
  
  return (
  <Layout>
    <SEO title="Work - INMAGIK" />
    <div className="jumbo-title jumbo-small p-4 container-fluid text-center bg-black">
      <div className="display-3 font-200 text-white">Portfolio</div>
      {/* <h1 className="display-4">INMAGIK</h1> */}
      <div className="row my-4">
        <div className="col-md-6 offset-md-3">
          <p className="lead font-200 text-white">
            <FormattedMessage id="portfolio_title">Some of the latest projects we worked on</FormattedMessage>
          </p>
        </div>
      </div>
    </div>

    <div className="main-content container py-5">
      <div className="row">
        {workData.length > 0 && workData.map(d => (
          <WorkPageItem key={d.id} datum={d}></WorkPageItem>
        ))}
      </div>
    </div>
   
  </Layout>
)}

export default injectIntl(WorkPage)

export const query = graphql`
  query {
    work: allWorkYaml {
      edges {
        node {
          id
          title
          description
          home
          role
          images {
            publicURL
          }
          lang
        }
      }
    }
     
  }
`